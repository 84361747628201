import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Cities() {
  const [selectedRoad, setSelectedRoad] = useState(null); 

  const handleClick = (roadName) => {
    setSelectedRoad(roadName);
  };
  
  useEffect(() => { AOS.init(); }, []);
  

  const cities =[
    {
      title:"Sohna Road",link:"/property-in-gurugram/sohna-road/",image:"../../OtherImages/sohnaroad.jpg"
    },
    {
      title:"Golf Course Road",link:"/property-in-gurugram/golf-course/",image:"../../OtherImages/golfcourse.webp"
    },
    {
      title:"MG Road",link:"/property-in-gurugram/mg-road/",image:"../../OtherImages/mgroad.jpg"
    },
    {
      title:"NPR Road",link:"/property-in-gurugram/northern-peripheral-road/",image:"../../OtherImages/sikanderpur.webp"
    },
    {
      title:"Dwarka Expressway",link:"/property-in-gurugram/dwarka-expressway/",image:"../../OtherImages/capture1.jpg"
    },
    {
      title:"New Gurgaon",link:"/property-in-gurugram/new-gurgaon/",image:"../../OtherImages/capture2.jpg"
    },
    {
      title:"Sohna",link:"/property-in-gurugram/sohna/",image:"../../OtherImages/capture3 (2).jpg"
    },
    {
      title:"SPR Road",link:"/property-in-gurugram/southern-peripheral-road/",image:"../../OtherImages/bgseven.jpg"
    },
    {
      title:"NH-48",link:"/property-in-gurugram/nh-48/",image:"../../OtherImages/capture5.jpg"
    },
    {
      title:"Golf Course Extn Road",link:"/property-in-gurugram/golf-course-extn-road/",image:"../../OtherImages/capture6.jpg"
    },
  ]
  
  return (
    <Wrapper className="section ">
      <div className="container">
      <div className="flex items-center justify-between mx-3 sm:mx-4 lg:mx-4 xl:mx-14 md:mx-4">
      <h1 className="text-3xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-left pt-4 ">
        Gurugram Prime Locations
        </h1>
      </div>
        {/* <div className="gdmJO d-flex justify-content-between px-4 text-center align-items-center">
          <div data-aos="flip-right"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"className="cvBMLN text-justify">
            <Link to={`/property-in-gurugram/sohna-road/`} target="_top">
              <div
                className={`asdDRsfVN bg-one ${
                  selectedRoad === "Sohna Road" ? "selected" : ""
                }`}
                onClick={() => handleClick("Sohna Road")}
              >
                <p className="items-center">Sohna Road</p>
              </div>
            </Link>
          </div>

          <div data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000" className="cvBMLN">
            <Link to={`/property-in-gurugram/golf-course/`} target="_top">
              <div
                className={`asdDRsfVN bg-two ${
                  selectedRoad === "Golf Course" ? "selected" : ""
                }`}
                onClick={() => handleClick("Golf Course")}
              >
                <p>Golf Course Road</p>
              </div>
            </Link>
          </div>

          <div data-aos="flip-right"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"className="cvBMLN">
            <Link to={`/property-in-gurugram/mg-road/`} target="_top">
              <div
                className={`asdDRsfVN bg-three  ${
                  selectedRoad === "MG Road" ? "selected" : ""
                }`}
                onClick={() => handleClick("MG Road")}
              >
                <p>MG Road</p>
              </div>
            </Link>
          </div>

          <div data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000" className="cvBMLN">
            <Link to={`/property-in-gurugram/northern-peripheral-road/`} target="_top">
              <div
                className={`asdDRsfVN bg-four ${
                  selectedRoad === "Northern Peripheral Road" ? "selected" : ""
                }`}
                onClick={() => handleClick("Northern Peripheral Road/")}
              >
                <p className="">Northern Peripheral Road</p>
              </div>
            </Link>
          </div>

          <div data-aos="flip-right"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"className="cvBMLN text-justify">
            <Link to={`/property-in-gurugram/dwarka-expressway/`} target="_top">
              <div
                className={`asdDRsfVN bg-five ${
                  selectedRoad === "Dwarka Expressway" ? "selected" : ""
                }`}
                onClick={() => handleClick("Dwarka Expressway")}
              >
                <p className="items-center">Dwarka Expressway</p>
              </div>
            </Link>
          </div>

          <div data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000" className="cvBMLN">
            <Link to={`/property-in-gurugram/new-gurgaon/`} target="_top">
              <div
                className={`asdDRsfVN bg-six  ${
                  selectedRoad === "New Gurgaon" ? "selected" : ""
                } `}
                onClick={() => handleClick("New Gurgaon")}
              >
                <p>New Gurgaon</p>
              </div>
            </Link>
          </div>

          <div data-aos="flip-right"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"className="cvBMLN">
            <Link to={`/property-in-gurugram/sohna/`} target="_top">
              <div
                className={`asdDRsfVN bg-seven  ${
                  selectedRoad === "Sohna" ? "selected" : ""
                }`}
                onClick={() => handleClick("Sohna")}
              >
                <p>Sohna</p>
              </div>
            </Link>
          </div>

          <div data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000" className="cvBMLN">
            <Link to={`/property-in-gurugram/southern-peripheral-road/`} target="_top">
              <div
                className={`asdDRsfVN bg-eight ${
                  selectedRoad === "Southern Peripheral Road" ? "selected" : ""
                }`}
                onClick={() => handleClick("Southern Peripheral Road")}
              >
                <p>Southern Peripheral Road</p>
              </div>
            </Link>
          </div>

          <div data-aos="flip-right"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000" className="cvBMLN text-justify">
            <Link to={`/property-in-gurugram/nh-48/`} target="_top">
              <div
                className={`asdDRsfVN bg-nine ${
                  selectedRoad === "NH-48" ? "selected" : ""
                }`}
                onClick={() => handleClick("NH-48")}
              >
                <p className="items-center">NH-48</p>
              </div>
            </Link>
          </div>

          <div data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"className="cvBMLN">
            <Link to={`/property-in-gurugram/golf-course-extn-road/`} target="_top">
              <div
                className={`asdDRsfVN bg-ten ${
                  selectedRoad === "Golf Course Extn Road" ? "selected" : ""
                }`}
                onClick={() => handleClick("Golf Course Extn Road")}
              >
                <p>Golf Course Extn Road</p>
              </div>
            </Link>
          </div>
        </div> */}
        <div className="grid lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 mx-0 gap-3 pb-2 pt-3">
                {cities.map((project, index) => (
                  <Link data-aos="flip-up" to={project.link} key={index} className="card">
                    <img src={project.image} alt={project.title} className="card-image" />
                    <button className="card-button bg-gradient-to-r from-[#C13B44] via-red-500 to-[#C13B44] hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800">{project.title}</button>
                  </Link>
                ))}
              </div>
      </div>
    </Wrapper>
  );
}

export default Cities;

const Wrapper = styled.section`
  .dffasPL {
    padding-top: 10px;
    padding-bottom: 20px;
  }
      .container {
    max-width: 1250px;
    margin: auto;
    padding: 10px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }

  .view-all {
    text-decoration: none;
    font-size: 1rem;
    color: #ff0000;
    font-weight: 600;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    text-decoration: none;
    background: #fff;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }

  .card:hover {
    transform: translateY(-5px);
  }

  .card-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 8px;
  }

  .card-image:hover{
    transform: scale(1.06);
  }

  .card-button {
    width: 100%;
    margin-top: 10px;
    border-radius:10px;
    padding: 10px 0px;
    background-color: #C13B44;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    text-transform: capitalize;
    transition: background-color 0.3s ease;
  }

  .card-button:hover {
    background-color: #7C1920;
    transform: scale(1.06);
  }

  @media (max-width: 768px) {
    .title {
      font-size: 1.25rem;
    }

    .card-image {
      height: 150px;
    }

    .card-button {
      font-size: 0.9rem;
    }
  }

  .bc-rd-23 {
    border-radius: 23px;
  }

  .bc-rd-15 {
    border-radius: 15px;
  }

  .ft-sz-15 {
    font-size: 15px;
  }

  .ft-sz-30 {
    font-size: 30px;
  }

  .ft-sz-25 {
    font-size: 25px;
  }

  .gdmJO {
    flex-wrap: wrap;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }

  .cvBMLN {
    width: calc(20% - 20px); /* Adjusted width and margin */
    margin: 10px;
    height: 200px;
    border: 3px solid #eee;
    overflow: hidden;
    position: relative;
    float: left;
    display: inline-block;
    cursor: pointer;
    border-radius: 13px;
    text-align: center;
  }

  .asdDRsfVN {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }

  .bg-one {
    background-image: url(../../OtherImages/sohnaroad.webp);
    background-position: center;
  }

  .bg-two {
    background-image: url(../../OtherImages/golfcourse.webp);
    background-position: center;
  }

  .bg-three {
    background-image: url(../../OtherImages/mgroad.webp);
    background-position: center;
  }

  .bg-four {
    background-image: url(../../OtherImages/sikanderpur.webp);
    background-position: center;
  }

  .bg-five {
    background-image: url(../../OtherImages/capture1.webp);
    background-position: center;
  }

  .bg-six {
    background-image: url(../../OtherImages/capture2.webp);
    background-position: center;
  }

  .bg-seven {
    background-image: url(../../OtherImages/capture3.webp);
    background-position: center;
  }

  .bg-eight {
    background-image: url(../../OtherImages/bgseven.webp);
    background-position: center;
  }

  .bg-nine {
    background-image: url(../../OtherImages/capture5.webp);
    background-position: center;
  }

  .bg-ten {
    background-image: url(../../OtherImages/capture6.webp);
    background-position: center;
  }
  .bg-one:hover,
  .bg-two:hover,
  .bg-three:hover,
  .bg-four:hover,
  .bg-five:hover,
  .bg-six:hover,
  .bg-seven:hover,
  .bg-eight:hover,
  .bg-nine:hover,
  .bg-ten:hover {
    // filter: grayscale(0.80);
    opacity: 1;
    // filter: brightness(90%);
    filter: gray saturate(0%) brightness(70%) contrast(1000%);
  }

  .cvBMLN:hover .asdDRsfVN,
  .cvBMLN:focus .asdDRsfVN {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

  .cvBMLN p {
    position: absolute;
    bottom: 0px;
    left: 10%;
    right: 10%;
    font-weight: bold;
    color: white;
    transition: 0.5s;
  }

  .cvBMLN:hover p {
    bottom: 35%;
  }

  @media screen and (max-width: 900px) {
    .cvBMLN {
      width: calc(45% - 10px); /* Adjusted width for tablet screens */
    }
  }

  @media screen and (max-width: 425px) {
    .cvBMLN {
      width: 100%;
    }
  }
`;
